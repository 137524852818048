/**
 * Table layout based
 * Responsive Vertical Align Utility Classes
 */
.height-full {
	height: 100%;
}

div[class^="valign-"], div[class*=" valign-"] {
	display: table;

	> div {
		display: table-cell;
	}
}

.valign-xs-top {
	> div {
		vertical-align: top;
	}
}

.valign-xs-middle {
	> div {
		vertical-align: middle;
	}
}

.valign-xs-bottom {
	> div {
		vertical-align: bottom;
	}
}

@include media-breakpoint-up(sm) {
	.valign-sm-top {
		> div {
			vertical-align: top;
		}
	}

	.valign-sm-middle {
		> div {
			vertical-align: middle;
		}
	}

	.valign-sm-bottom {
		> div {
			vertical-align: bottom;
		}
	}
}

@include media-breakpoint-up(md) {
	.valign-md-top {
		> div {
			vertical-align: top;
		}
	}

	.valign-md-middle {
		> div {
			vertical-align: middle;
		}
	}

	.valign-md-bottom {
		> div {
			vertical-align: bottom;
		}
	}
}

@include media-breakpoint-up(lg) {
	.valign-lg-top {
		> div {
			vertical-align: top;
		}
	}

	.valign-lg-middle {
		> div {
			vertical-align: middle;
		}
	}

	.valign-lg-bottom {
		> div {
			vertical-align: bottom;
		}
	}
}

@include media-breakpoint-up(xl) {
    .valign-xl-top {
        > div {
            vertical-align: top;
        }
    }

    .valign-xl-middle {
        > div {
            vertical-align: middle;
        }
    }

    .valign-xl-bottom {
        > div {
            vertical-align: bottom;
        }
    }
}

/**
 * Responsive text alignment Utility Classes
 */
@include media-breakpoint-up(sm) {
	.sm {
		&-text-left {
			text-align: left;
		}

		&-text-center {
			text-align: center;
		}

		&-text-right {
			text-align: right;
		}
	}
}

@include media-breakpoint-up(md) {
	.md {
		&-text-left {
			text-align: left;
		}

		&-text-center {
			text-align: center;
		}

		&-text-right {
			text-align: right;
		}
	}
}

@include media-breakpoint-up(lg) {
	.lg {
		&-text-left {
			text-align: left;
		}

		&-text-center {
			text-align: center;
		}

		&-text-right {
			text-align: right;
		}
	}
}

/* Responsive float utilities*/
@include media-breakpoint-up(sm) {
	.sm {
		&-left {
			float: left;
		}

		&-right {
			float: right;
		}
	}
}

@include media-breakpoint-up(md) {
	.md {
		&-left {
			float: left;
		}

		&-right {
			float: right;
		}
	}
}

@include media-breakpoint-up(lg) {
	.lg {
		&-left {
			float: left;
		}

		&-right {
			float: right;
		}
	}
}

.py-section {
	padding-top: $padding-section-vertical;
	padding-bottom: $padding-section-vertical;
}

.pt-section {
	padding-top: $padding-section-vertical;
}

.pb-section {
	padding-bottom: $padding-section-vertical;
}