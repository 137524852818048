@use "sass:math";

@function calculateRem($size) {
    $remSize: math.div($size, 16px);
    @return #{$remSize}rem;
}

@mixin font-size($size) {
    font-size: $size; //Fallback in px
    font-size: calculateRem($size);
}