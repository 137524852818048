.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.f-14 {
  @include font-size(14px);
}

.f-18 {
  @include font-size(18px);
}