//----------------------------------------------------------//
//	OPTIONS
//----------------------------------------------------------//
$enable-caret: true;
$enable-rounded: true;
$enable-shadows: true;
$enable-smooth-scroll: false;
$enable-negative-margins: true;
//----------------------------------------------------------//
//	SPACING
//----------------------------------------------------------//
$spacer: 1rem;
$spacers: (0: 0,
        1: ($spacer * 0.25,
        ),
        2: ($spacer * 0.5,
        ),
        3: ($spacer * 0.75,
        ),
        4: $spacer,
        5: ($spacer * 1.25,
        ),
        6: ($spacer * 1.5,
        ),
        7: ($spacer * 1.75,
        ),
        8: ($spacer * 2,
        ),
        9: ($spacer * 2.25,
        ),
        10: ($spacer * 2.5,
        ),
        11: ($spacer * 3,
        ),
        12: ($spacer * 3.5,
        ),
        13: ($spacer * 4,
        ),
        14: ($spacer * 4.5,
        ),
        15: ($spacer * 5,
        ),
        16: ($spacer * 6,
        ),
        17: ($spacer * 7,
        ),
        18: ($spacer * 8,
        ),
        19: ($spacer * 9,
        ),
        20: ($spacer * 10,
        ),
        21: ($spacer * 12.5,
        ),
        22: ($spacer * 15,
        ),
        23: ($spacer * 17.5,
        ),
        24: ($spacer * 20,
        ),
        25: ($spacer * 22.5,
        ),
);
//$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null);
/*
 * set the fonts for use
 */
@import url('https://fonts.googleapis.com/css?family=Lato|Montserrat:400,700&display=swap');
$font-family-title:  Montserrat, Helvetica, Arial, sans-serif;

/*
 * BOOTSTRAP
 * #####################
 */
$primary: #84c44c;
$secondary: #1f5ca9;
$font-family-base: Lato, Helvetica, Arial, sans-serif;
$body-color: #777;
$headings-font-family: $font-family-title;
$headings-font-weight: 700;
$headings-color: #000;
$link-color: $primary;
$link-hover-color: $secondary;

// Basics of a navbar
$navbar-padding-y: .7rem;
$navbar-custom-bg: #000;
$navbar-custom-color: #fff;
$navbar-custom-hover-color: rgba(#fff, .75);
$navbar-custom-active-color: $primary;
$navbar-custom-disabled-color: rgba(#fff, .25);
$navbar-custom-toggler-border-color: $primary;
//$navbar-light-toggler-icon-bg internally uses $navbar-light-color;
$navbar-light-color: $primary;

/*
 * Dropdown Menu
 */
//$dropdown-color: #fff;
//$dropdown-bg: #000;
//$dropdown-link-color: #fff;
//$dropdown-link-hover-color: $primary;
//$dropdown-link-hover-bg: #222;


/*
 * UTILITIES
 * #####################
 */
$padding-section-vertical: 40px;
$gallery-gutter: 10px;



/*
 * MODULES
 * #####################
 */
// Icon fonts
//$icomoon-font-path: "/assets/fonts";

// Social Media Icons
$google: #e44134;
$facebook: #4470cf;
$facebookmessenger: #007ff8;
$instagram: #d53581;
$linkedin: #3393c1;
$skype: #2ebbf0;
$twitter: #5daed5;
$youtube: #c8312b;
$whatsapp: #00a859;
$brand-colors: (
        "instagram": $instagram,
        "facebook-messenger": $facebookmessenger,
        "linkedin": $linkedin,
        "facebook": $facebook,
        "youtube": $youtube,
        "twitter": $twitter,
        "whatsapp": $whatsapp,
        "google": $google,
        "skype": $skype,
);


/*
 * PROJECT variables
 * #####################
 */


