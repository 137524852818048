#company {
	padding-top: 4em;
	padding-bottom: 4em;
	border-bottom: solid 1px #cfcfcf;
	background-color: #eee;
}

//#parking {
//
//}
