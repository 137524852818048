
.social-media {
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;

	li.social-media {
		margin: 0 2px;

		&-facebook {
			.svg-icon {
				fill: map-get($brand-colors, facebook);
			}
		}

		&-instagram {
			.svg-icon {
				fill: map-get($brand-colors, instagram);
			}
		}

		&-google {
			.svg-icon {
				fill: map-get($brand-colors, google);
			}
		}
	}

	a {
		text-decoration: none;
		position: relative;
		display: inline-flex;
		transition: all .4s ease;

		&:hover,
		&:focus {
			text-decoration: none;
			transform: translateY(-0.4rem);
		}
	}
}
