// this is animate module
/*:root {
// change this variable to change the default period;
  --animate-delay: 1s;
}*/
.animate__animated {
  &.animate__delay- {
    &200ms {
      animation-delay: 200ms;
    }

    &400ms {
      animation-delay: 400ms;
    }
  }
}