//Import CPLibrary Variables
@import "cp-library/scss/variables";

//Import Boilerplate Variables
@import "variables";

// Import bootstrap 5
@import "bootstrap-5/scss/functions";
@import "bootstrap-5/scss/variables";
@import "bootstrap-5/scss/mixins";
@import "bootstrap-5/scss/utilities";

// temp class to fix media query packer bug;
// this will make sure that the media queries are packed in order
// also moved purgeCss in build below media-query to make sure the order remain correct even when this class is not used;
.null {
  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }

  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1rem;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 1rem;
  }
}

// Layout & components
@import "bootstrap-5/scss/root";
@import "bootstrap-5/scss/reboot";
@import "bootstrap-5/scss/type";
@import "bootstrap-5/scss/images";
@import "bootstrap-5/scss/containers";
@import "bootstrap-5/scss/grid";
//@import "bootstrap-5/scss/tables";
//@import "bootstrap-5/scss/forms";
//@import "bootstrap-5/scss/buttons";
//@import "bootstrap-5/scss/transitions";
//@import "bootstrap-5/scss/dropdown";
//@import "bootstrap-5/scss/button-group";
//@import "bootstrap-5/scss/nav";
//@import "bootstrap-5/scss/navbar";
//@import "bootstrap-5/scss/card";
//@import "bootstrap-5/scss/accordion";
//@import "bootstrap-5/scss/breadcrumb";
//@import "bootstrap-5/scss/pagination";
//@import "bootstrap-5/scss/badge";
//@import "bootstrap-5/scss/alert";
//@import "bootstrap-5/scss/progress";
//@import "bootstrap-5/scss/list-group";
//@import "bootstrap-5/scss/close";
//@import "bootstrap-5/scss/toasts";
//@import "bootstrap-5/scss/modal";
//@import "bootstrap-5/scss/tooltip";
//@import "bootstrap-5/scss/popover";
//@import "bootstrap-5/scss/carousel";
//@import "bootstrap-5/scss/spinners";

// Helpers
@import "bootstrap-5/scss/helpers";

// Utilities
@import "bootstrap-5/scss/utilities/api";

// CPLibrary Utilities
@import "cp-library/scss/utilities-bootstrap4";

@import "mixins";
@import "utilities";
@import "layout";

// animate.css
@import "animate.css/animate";
// more animate classes;
@import "modules/animate";
// AOS Library CSS
@import "aos/dist/aos";

/*
 * MODULES
 * ==============
 */
//@import "modules/loading";
@import "modules/svg";
//@import "modules/button-bg-slide";
//@import "modules/button-outline-bg-slide";
//@import "modules/footer";
//@import "modules/forms";
//@import "modules/navbar";
//@import "modules/gallery";
@import "modules/social";



/*
 * PAGES
 * ===========
 */
//@import "pages/contact";
//free parking styles;
@import "pages/free-parking";


/*
 * VENDORS
 * ===================
 */
//iconfonts
//@import "vendor/iconfont/variables";
//@import "vendor/iconfont/style";

//fancybox 3
//@import "@fancyapps/fancybox/dist/jquery.fancybox";
// fancybox 4
//@import "@fancyapps/ui/dist/fancybox";

// Glide slider
//@import "swiper/swiper";
//@import "swiper/modules/navigation/navigation";
//@import "swiper/modules/pagination/pagination";
//@import "swiper/modules/effect-fade/effect-fade";
//@import "modules/swiper";




