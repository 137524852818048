.svg-icon {
  fill: #222;
  width: 30px;
  height: 30px;

  &.svg-sm {
    width: 18px;
    height: 18px;
  }

  &.svg-lg {
    width: 46px;
    height: 46px;
  }
}
.svg-stroke {
  fill: $secondary;
}

.svg-fill {
  fill: lighten($primary, 20%);
}
